import styled from 'styled-components';

const RegularFont = 'Rubik-Regular';
const SemiBold = 'Rubik-Bold';

const Title = styled.div`
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  padding: 25px 0px 15px 25px;
  text-transform: capitalize;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 65%;
  &:first-letter {
    text-transform: capitalize;
  }

  @media (max-width: 500px) {
    display: block;
    .Name {
      width: 100%;
    }
    .Circle {
      margin-left: -10px;
      margin-top: 10px;
      display: inline-block;
    }
  }
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Card = styled.div`
  box-shadow: 0 0 21px rgba(0,0,0,0.08);
  background-color: #ffffff;
  float: left;
  margin-left: 25px;
  margin-bottom: 25px;
  padding: 0px!important;
  position: relative;
  width: calc(100% - 25px);
  display: flex;
  flex-wrap: wrap;
  
  > img {
    width: 100%;
    height: 235px;
    float: left;
    cursor: pointer;
  }

  .educateImageBackground {
    position: absolute;
    width: 100%;
    height: 235px;
    background-color: black;
    opacity: 0.6;
    display: flex;
    align-items: center;
    justify-content: center;
    .getStarted {
      font-size: 14px;
      letter-spacing: 0px;
      color: rgb(255,255,255);
      font-family: ${RegularFont};
      width: 122px;
      height: 37px;
      background-color: rgba(190,228,200,0);
      border: 1px solid #ffffff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .content {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    float: left;
    width: 100%;
    height: calc(100% - 235px);
    .typeOfWork {
      font-size: 13px;
      letter-spacing: 0px;
      color: rgb(21,159,202);
      font-family: ${RegularFont};
      margin-bottom: 5px;
      text-align: left;
      text-transform: lowercase;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      &:first-letter {
        text-transform: capitalize;
      }
    }
    .workName {
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${RegularFont};
      text-align: left;
      cursor: pointer;
    }
    .description {
      font-size: 13px;
      letter-spacing: 0px;
      line-height: 18px;
      color: rgb(153,153,153);
      font-family: ${RegularFont};
      text-align: left;
    }
  }
`;

const Border = styled.div`
 width: 100%;
 margin-top: 25px;
 border-bottom: 1px solid rgba(0, 92, 135, 0.10);
`;

const EducateContent = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 5px;
  align-items: center;
  .work {
    display: inline-block;
    width: 90%;
    text-align: left;
    > div {
      font-size: 18px;
      letter-spacing: 0px;
      color: rgb(51,51,51);
      font-family: ${RegularFont};
    }
  }
  .svg {
    width: 10%;
    > svg {
      width: 19px;
      height: 19px;
      float: right;
    }
  }

  .workEducation {
    width: 60%;
  }
  .workEducationCheck {
    width: 40%;
    text-align: right;
    display: ${({displayNone}) => displayNone && 'none'};
  }
`;

const ChampionsCard = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${({grid}) => grid ? 'calc(100%/'+grid+' - 0.01px)':'50%'};
  margin-bottom: 20px;
  @media(max-width: 1200px) {
    width: ${({grid}) => grid ? 'calc(100%)':'50%'};
    display: ${({grid}) => grid && 'inline-block'};
    justify-content: ${({grid}) => grid &&  "center"};
    align-items: ${({grid}) => grid && 'center' };
  }
  @media(max-width: 600px) {
    width: 100%;
  }
  > div {
    padding: 5px;
    height: 94%;
  }
`;

const CircleContainer = styled.div`
  display: inline-block;
  margin: ${({margin}) => margin ? margin: ''};
  margin-right: ${({marginRight, marginRightSide}) => marginRight ? '0px': marginRightSide ? "20px" : '0px'};
  @media (max-width: 660px){
    margin-right: 0px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }
  .quiz{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    font-size: ${({font}) => font ? '14px': '12px'};
    line-height: 12px;
    color: #9c9c9c;
  }
`;

const CircleContainerNew = styled.div`
  display: flex;
  margin-right: ${({marginRight}) => marginRight ? '0px': '0px'};
  @media (max-width: 660px){
    margin-right: 0px;
  }
  .flex {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
  }
  .quiz{
    font-family: Rubik;
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    font-size: ${({font}) => font ? '14px': '12px'};
    line-height: 12px;
    color: #3a3a3a;
    margin: auto;
    margin-left: 5px;
  }
`;

const Circle = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 16px;
  border: ${({checked }) => checked  ? '3px solid #96C26E':'3px solid #9c9c9c'};
  border-color: ${({ checked }) => checked ? '#96C26E' : '#9c9c9c'};
`;

const Tick = styled.div`
  content: "";
  position: relative;
  display: block;
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border-style: solid;
  border-width: 0 3px 3px 0;
  border-color: ${({ checked }) => checked ? '#96C26E' : 'white'};
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-top-color: transparent;
  border-left-color: transparent;
`;

const HeaderTitle = styled.div`
  font-size: 14px;
  letter-spacing: 0px;
  color: #333333;
  font-weight: 500;
`;

const InfoCircle = styled.div`
  width: ${({ width }) => width ? width : '25px'};
  height: ${({ height }) => height ? height : '25px'};
  border-radius: ${({ radius }) => radius ? radius : '12px'};
  display: flex;
  border: 2px solid #999999;
  align-items: center;
  justify-content: center;
  color: ${({ info }) => info ? 'white' : '#999999'};
  background-color: ${({ info }) => info && '#999999'};
  font-size: ${({ info }) => info ? '24px' : '14px'};
  font-weight: bold;
  font-family: ${SemiBold};
  cursor: pointer;
  font-family: Proxima-Nova;
  font-weight: 800;
`;

const AdventureCard = styled.div`
  float: left;
  width: ${({width}) => width ? width: '100%'};
  display: inline-block;
  font-family: ${RegularFont};
  margin-bottom: 5px;
  .card {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    .flex {
      display: flex;
      width: 100%;
      .block {
        width: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        padding: 10px;
        div{
          .imgWidth {
          width: 30px;
        }
        >img{
          width: 40px;
          height: 40px;
        }

        }
        .imgWidth {
          width: 30px;
        }
        >img{
          width: 40px;
          height: 40px;
        }
      }
      .text {
        width: 50%;
        font-size: 12px;
        letter-spacing: 0px;
        color: #333333;
        font-family: ${SemiBold};
        text-align: left;
        padding-right: 0px;
        font-family: Rubik-Regular;
font-style: normal;
font-weight: normal;
font-size: 16px;
line-height: 16px;
color: #0D4270;
margin: auto;
text-transform: none;
      }
      .overlap {
        position: absolute;
        z-index: 1;
        left: calc(50% - 10px);
        top: calc(50% - 10px);
      }
    }
  }
  @media (max-width: 800px) {
    height: 200px;
  }
`;

const AdventureCardNew = styled.div`
  float: left;
  width: ${({width}) => width ? width: '100%'};
  display: inline-block;
  font-family: ${RegularFont};
  margin-bottom: 5px;
  .card {
    background-color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    .flex {
      display: flex;
      min-width: 355px;
      .block {
        width: 40%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 10px;
        position: relative;
        .imgWidth {
          width: 30px;
        }
        >img{
          height: 100px;
          width: auto;
        }
      }
      .text {
        width: 50%;
        font-size: 12px;
        letter-spacing: 0px;
        color: #333333;
        font-family: ${SemiBold};
        text-align: center;
        padding: 0px 15px;
        padding-right: 0px;
        padding-top: 25px;
        >p{
          color: rgb(21,159,202);
        }
      }
      .overlap {
        position: absolute;
        z-index: 1;
        left: calc(50% - 10px);
        top: calc(50% - 10px);
      }
    }
  }
  @media (max-width: 800px) {
    height: 200px;
  }
`;

const NumberFont = styled.div`
width: 30%;
display: flex;
justify-content: flex-end;
  font-size: 30px;
  letter-spacing: 0px;
  color: ${({color}) => color && color};
  font-family: ${RegularFont};
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  padding-right: 10px;
  font-family: Rubik-Medium;
font-style: normal;
font-weight: 500;
font-size: 36px;
line-height: 24px;
margin: auto;
.slash{
  font-family: Rubik-Medium;
  font-size: 18px;
  color: #0D4270;
  margin-top: 5px;
  margin-left: 5px;
}
.total{
  font-family: Rubik-Medium;
  font-size: 18px;
  color: #0D4270;
  margin-top: 5px;
}
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
  width: 100%;

  .expertImage {
    width: 100%;
    height: 100%;
    >img {
      width: 100%;
      height: 100%;
      max-height: 220px;
    }
  }
  .expertName {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 28px;
    color: #333333;
    text-transform: capitalize;
    font-family: ${RegularFont};
    text-align: center;
    margin: 15px 0px;
  }
`;

const ArticleButton = styled.button`
  width: 27px;
  height: 27px;
  border-radius: 50%;
  background-color: ${({ isUnlocked }) => isUnlocked ? '#159fc9' : '#dddbdb'};
  margin-right: 7px;
  border: none;
  padding: 0;

  font-size: 14px;
  letter-spacing: 0px;
  color: ${({ isUnlocked }) => isUnlocked ? 'white' : 'rgb(153,153,153)'};
  cursor: ${({ isUnlocked }) => isUnlocked ? 'pointer' : 'not-allowed'};

  &:active, &:focus {
    outline: none;
  }

  &:last-child {
    margin-right: 0;
  }
`;

const ArticleButtonsContainer = styled.div`
  display: inline-block;
  padding-top:10px;
  width: auto;
  margin-left: 27px;
  float: ${({ right }) => right ? 'right' : 'none'};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom };
  margin-right: ${({ marginBottom }) => marginBottom && '25px' };
  .inlineBlock {
    display: inline-block;
    @media(max-width: 900px) {
      width: 100%;
      text-align: right;
    }
  }
`;

const BackButton = styled.button`
  width: 85px;
  height: 29px;
  border-radius: 15px;
  background-color: #dddbdb;
  border: none;
  padding: 0;

  font-size: 12px;
  letter-spacing: 0px;
  color: rgb(102,102,102);

  &:active, &:focus {
    outline: none;
  }

  > img {
    margin-right: 4px;
    margin-top: -2px;
  }
`;

const PaginationCircle = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 6px;
  background-color: ${({ active }) => active ? '#0D4270' : ''};
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: ${RegularFont};
  cursor: pointer;
  > div {
    color: ${({ active }) => active ? '#ffffff' : '#999999' };
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;



const PaginationCircleNew = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: ${({ active }) => active ? '#007AB1' : ''};
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: ${RegularFont};
  cursor: pointer;
  > div {
    color: ${({ active }) => active ? '#ffffff' : '#007AB1' };
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

const HaederName = styled.div`
width: 67.1%;
display: flex;
background: linear-gradient(rgb(82, 168, 237) 0%, rgb(138, 211, 247) 100%);
margin-bottom: 25px;
border-radius: 6px;
>div:first-child {
  width: 60%;
  display: flex;
  >div:first-child{
    background: white;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    border-radius: 6px;
  >img{
    width: 32px;
    height: 32px;
    margin: auto;
    display: flex;
  }
}
>div:nth-child(2){
  display: flex;
  margin: auto;
  margin-left: 10px;

  >span{
    text-transform: capitalize;
    font-family: Rubik-Medium;
      font-style: normal;
      font-size: 18px;
      line-height: 24px;
      color: white;
  }
}
}

`;

const DropDownContainer = styled.div`
  @media (max-width: 500px) {
    margin-top: 15px;
  }
`;

const ImageIcon = styled.div`
  width: 40%;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  padding-right: 15px;
  >img{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
`;

const Layout = styled.div`

margin-top: ${({ margin }) => margin ? '0px' : '-75px' };
`;

export { PaginationCircle, PaginationCircleNew, FlexBox, NumberFont, AdventureCard, InfoCircle, HeaderTitle, Tick, CircleContainer, Circle, Title, Card, CardContainer,
  Border, EducateContent, ChampionsCard, ArticleButton, ArticleButtonsContainer, BackButton, AdventureCardNew, HaederName, DropDownContainer, ImageIcon, CircleContainerNew, Layout };
