import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import isUndefined from 'lodash/isUndefined';
import isNull from 'lodash/isNull';
import isEmpty from 'lodash/isEmpty';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PaginationCircle, CardContainer, HaederName, ImageIcon, Layout } from './styles';
import { SplitEngagementSection, EngagementContainer } from '../EngagementSection/styles';
import { NoComments } from '../SocialFeeds/styles';
import Waiting from '../Waiting';
import AdventurePointDashBoard from '../AdventurePointDashBoard';
import ArticleCard from '../AdventureCategories/recommendedArticles';
import { getAdventuresByCategory } from '../../redux/actions';
import { ImageUrlNew } from '../../utils/constants';
import StyledDropDown from '../DropDown/dropDownNew';
import { AdminButtons } from '../Article/styles';
import { withTranslation } from 'react-i18next';

class Journeys extends Component {
  constructor() {
    super();
    this.state = {
      minLimit: 1,
      maxLimit: 5,
      total: 18,
      active: 1,
      dropdown: 0,
      selectedItem: '',
      isOpen: false,
      open: false
    }
  }

  componentDidMount() {
    const { location, fetchAdventuresByCategory } = this.props;
    const StringArray = location.pathname.split('/');
    fetchAdventuresByCategory(StringArray[(StringArray.length - 1)]);
  }

  setPagination = (activeIndex) => {
    if (activeIndex === 1) {
      this.setState({
        minLimit: 1,
        maxLimit: 5,
        total: 18,
        active: 1
      })
    }
    else {
      let remainingValue = this.state.total - activeIndex;
      if (remainingValue >= 4) {
        this.setState({
          minLimit: activeIndex - 1,
          maxLimit: activeIndex + 3,
          active: activeIndex
        })
      }
      else {
        this.setState({
          maxLimit: activeIndex + remainingValue,
          minLimit: activeIndex + remainingValue - 4,
          active: activeIndex
        })
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.location !== this.props.location) {
      const { location, fetchAdventuresByCategory } = this.props;
      const StringArray = location.pathname.split('/');
      fetchAdventuresByCategory(StringArray[(StringArray.length - 1)]);
    }
  }

  changeTickValue = (index, objectName) => {
    const { adventureDetails } = this.state;
    adventureDetails[index][objectName] = !adventureDetails[index][objectName];
    this.setState({
      adventureDetails
    })
  };

  showArticle = (adventure, isNextArticle) => {
    const { location, history } = this.props;
    if (adventure.lock_status === 1 || isNextArticle) {
      const currentLocation = location.pathname;
      history.push(`${currentLocation}/article/${adventure.id}`);
    } else {
      toast.info('This article is locked, please read the previous articles in the same category to unlock');
    }
  };

  getQuizForArticle = (adventure) => {
    const { location, history } = this.props;
    const currentLocation = location.pathname;
    history.push(`${currentLocation}/article/${adventure.id}/quiz`);
  };

  getEducationDashboard = (adventuresDetails, newIndex) => (
    adventuresDetails.map((data, index) => (
      <ArticleCard newIndex={newIndex} onClickFavoriteRibbon={this.props.onClickFavoriteRibbon} showArticle={this.showArticle} journeyPage={1} data={data} key={index} history={this.props.history} margin={index === 1 ? "0px" : "0px"} width={'calc((100% / 2) - 12.5px)'} bottom={'25px'} />
    ))
  );

  getPaginationCircle = () => {
    let circleButton = [];
    for (let i = this.state.minLimit; i <= this.state.maxLimit; i++) {
      circleButton.push(<PaginationCircle key={i} active={i == this.state.active} onClick={() => this.setPagination(i)}><div>{i}</div></PaginationCircle>)
    }
    return circleButton;
  }

  showDropDown = () => {
    this.setState({
      dropdown: !this.state.dropdown,
      open: true
    })
  };

  getMenuItemsForDropDown = () => {

    const { adventureCategories } = this.props;
    let menuItems = [];
    menuItems = adventureCategories && adventureCategories.map((category) => category.category_name);
    return menuItems;
  };

  onSelect = (value) => {
    this.setState({
      selectedItem: value
    });
  };

  addCategory = (categoryName, categories) => {
    let index = categories.findIndex((data) => data.category_name.toLowerCase() == categoryName.toLowerCase());
    return index != -1 ? categories[index].image : "test.png";
  };

  render() {
    const { adventures, location, history, adventureCategories,createArticle, t} = this.props;
    const { dropdown, open } = this.state;
    if (isUndefined(adventures) || isNull(adventures)) {
      return <Waiting />
    }

    const newIndex = adventures.findIndex((adventure) => adventure.lock_status === 0);

    const stringArray = location.pathname.split('/');
    const categoryName = stringArray[stringArray.length - 1];

    return (
      <Layout margin={((history.location.pathname == '/education/adventure/fitness'))}>
        <HaederName>
          <div>
            <div>
              <img
                src={`${ImageUrlNew}/${this.addCategory(categoryName, adventureCategories)}`}
              />
            </div>
            <div>
              <span>
                {`${t(categoryName)} ${t("Articles")}`}
              </span>
            </div>
          </div>
          <ImageIcon position={open ? 'relative' : ''}> <img src="/public/images/NewDashboardV2/menuicon.png" alt="back-arrow" onClick={() => this.showDropDown()} />
          </ImageIcon>
        </HaederName>
        {dropdown !== 0 ?
          <StyledDropDown
            title={t("Adventures Categories")}
            MenuItems={adventureCategories}
            isAdventurePage={true}
            history={history}
            onSelect={this.onSelect}
            isOpen={true}
          />
          : ''}
        <EngagementContainer>
          <SplitEngagementSection gridWidth="67.1%" smallWidth="100%">
            <CardContainer>
              {isEmpty(adventures) ? <NoComments>{t(`No Adventures in this category`)}</NoComments> : this.getEducationDashboard(adventures, newIndex)}
            </CardContainer>
          </SplitEngagementSection>
          <SplitEngagementSection PaddingRight={1} smallPadding="0px 10px" smallWidth="100%" style={{ "marginTop": "-75px" }}>
            { createArticle &&
              <AdminButtons background={"#9FC989"} onClick={() => history.push('/education/create-adventure')}>
                <div>
                  <img src="/public/images/NewDashboardV2/editarticle.png" alt="back-arrow" />
                </div>
                <div>
                  {t("Create Journey")}
                </div>
              </AdminButtons>
            }
            {createArticle &&
              <AdminButtons background={"#69C2FF"} onClick={() => history.push('/education/create-category')}>
                <div>
                  <img src="/public/images/NewDashboardV2/createjourney.png" alt="back-arrow" />
                </div>
                <div>
                  {t("Create Category")}
                </div>
              </AdminButtons>
            }
            <AdventurePointDashBoard />
          </SplitEngagementSection>
        </EngagementContainer>
      </Layout>
    )
  }
}

Journeys.propTypes = {
  adventures: PropTypes.array,
  fetchAdventuresByCategory: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  adventureIds: PropTypes.array,
  count: PropTypes.number,
  onClickFavoriteRibbon: PropTypes.func,
  adventureCategories: PropTypes.array.isRequired,
  isOpen: PropTypes.bool,
  createArticle: PropTypes.bool,
  role: PropTypes.string.isRequired,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  adventures: state.education.adventures,
  adventureIds: state.education.adventureIds,
  count: state.education.count,
  adventureCategories: state.education.adventureCategories,
});

const mapDispatchToProps = (dispatch) => ({
  fetchAdventuresByCategory: (categoryName) => dispatch(getAdventuresByCategory(categoryName))
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(Journeys)));

